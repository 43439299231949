<template>
  <div>
    <transition name="dialog">
      <div v-if="showConfirm" :class="['component-confirm-con', type === '1' ? '' : 'new-com-width']" :style="{ 'z-index': zIndex + 2 + ' !important' }">
        <div class="confirm-content font-blod" v-if="type === '1'">{{ text }}</div>
        <div v-else-if="type === '2'">
          <div class="confirm-title font-blod">{{ title }}</div>
          <div class="confirm-content2" v-if="contentType === 1">{{ text }}</div>
          <div class="confirm-content2 c2-scroll-top" :style="{ 'max-height': countAdaptHeight + 'vw' }" v-else-if="contentType === 2">{{ text }}</div>
          <div class="confirm-content2 c2-input-width" v-else-if="contentType === 3">
            <div class="c2-input-con">
              <input type="text" v-model.trim="inputVal" :placeholder="placeholder" :maxlength="maxLength" />
            </div>
          </div>
          <div class="confirm-content2 c2-input-width" v-else-if="contentType === 4">
            <div class="confirm-content2" style="margin: 0">{{ text }}</div>
            <div class="row-center-center">
              <div class="c2-input-con flex-auto">
                <input type="text" v-model.trim="inputVal" :placeholder="placeholder" :maxlength="maxLength" />
              </div>
              <div class="unit">{{ inputUnit }}</div>
            </div>
          </div>
        </div>
        <div class="confirm-btn-list">
          <div :class="['confirm-btn-cancle', { 'part-two': btnTextArr.length == 2 }]" @click="firstBtnFn">{{ btnTextArr[0] }}</div>
          <div :class="['confirm-btn-sure', { 'part-two': btnTextArr.length == 2 }]" @click="secondBtnFn" v-if="btnTextArr.length == 2">{{ btnTextArr[1] }}</div>
        </div>
      </div>
    </transition>
    <div :style="{ 'z-index': zIndex + ' !important' }" v-show="showConfirm" class="component-confirm" @touchmove.prevent></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inputVal: '', // 输入框input值
    }
  },
  props: {
    // 控制弹窗显示
    showConfirm: {
      type: Boolean,
      default: false,
    },
    // 层级
    zIndex: {
      type: Number,
      default: 1000,
    },
    // 弹窗类型
    type: {
      type: String,
      default: '1',
    },
    // 文字标题
    title: {
      type: String,
      default: '',
    },
    titleSmall: {
      type: String,
      default: '',
    },
    // 弹窗内容类型
    contentType: {
      type: Number,
      default: 1,
    },
    // input框长度限制
    maxLength: {
      type: Number,
      default: 300,
    },
    // placeholder文字修改
    placeholder: {
      type: String,
      default: '',
    },
    // 弹窗文字
    text: {
      type: String,
      default: '',
    },
    // 弹窗高度
    maxHeight: {
      type: Number,
      default: 650,
    },
    // 弹窗按钮文字
    btnTextArr: {
      type: Array,
      default: () => ['取消', '确定'],
    },
    // 点击第一个按钮
    firstBtn: {
      type: Function,
      default: null,
    },
    // 点击第二个按钮
    secondBtn: {
      type: Function,
      default: null,
    },
    inputUnit: {
      type: String,
      default: null,
    },
    scale: {
      type: String,
      default: '1',
    },
  },
  computed: {
    // 计算弹窗文本内容最高值
    countAdaptHeight() {
      return (this.maxHeight / this.$appConfig.viewPortAdapt.viewportWidth) * 100
    },
  },
  methods: {
    firstBtnFn() {
      this.$emit('firstBtn', this.inputVal)
    },
    secondBtnFn() {
      this.$emit('secondBtn', this.inputVal)
    },
  },
}
</script>
<style lang="less" scoped>
.font-blod {
  font-weight: bold;
}
.dialog-enter-active {
  animation: move1 0.5s;
}
// .dialog-leave-active{
//     animation: move2 .3s;
// }
.dialog-enter, .dialog-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: scale(0.9);
  opacity: 0;
}
.component-confirm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10003;
  background-color: rgba(16, 16, 16, 0.5);
  overflow: hidden;
}
.new-com-width {
  width: 630px !important;
}
.component-confirm-con {
  width: 550px;
  border-radius: 12px;
  background-color: #fff;
  overflow: hidden;
  z-index: 10004;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  overflow: hidden;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.confirm-title {
  font-size: 36px;
  color: #242831;
  padding-top: 60px;
  text-align: center;
}
.confirm-2-title {
  font-size: 28px;
  color: #242831;
  padding-top: 32px;
  text-align: center;
}
.confirm-content {
  width: 430px;
  min-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  margin: 0 auto;
  line-height: 48px;
  box-sizing: border-box;
  padding: 40px 0;
  text-align: center;
}
.confirm-content2 {
  margin-top: 52px;
  min-height: 104px;
  font-size: 28px;
  color: #242831;
  box-sizing: border-box;
  text-align: center;
  padding: 0 60px 72px 60px;
  line-height: 44px;
  text-align: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.c2-scroll-top {
  padding: 10px 60px 34px 60px;
}
.c2-input-width {
  padding: 14px 32px 52px 32px !important;
  .c2-input-con {
    background-color: #f1f4f9;
    padding: 0 40px;
    box-sizing: border-box;
    input {
      width: 100%;
      height: 88px;
      background-color: #f1f4f9;
      border-radius: 12px;
      font-size: 28px;
      color: #9aa1a9;
      border: 0;
    }
  }
  .unit {
    color: #3c454e;
    margin: 0 0 0 12px;
  }
}
.confirm-btn-list {
  width: 100%;
  height: 105px;
  border-top: #eaeaea;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #eaeaea;
  div {
    width: 50%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff6e00;
    font-size: 32px;
  }
  .part-two {
    &:first-child {
      color: #9aa1a9;
      border-right: 1px solid #eaeaea;
    }
  }
}
// 动画
@keyframes move1 {
  /*这个就是要执行的动画*/
  from {
    transform: translate3d(-50%, -50%, 0) scale(0.7);
  }
  /*从它到它，scale()方法，该元素增加或减少的大小，取决于宽度（X轴）和高度（Y轴）的参数，这个是css3中2D的转换方法。*/
  to {
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}
@keyframes move2 {
  /*这个就是要执行的动画*/
  from {
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
  /*从它到它，scale()方法，该元素增加或减少的大小，取决于宽度（X轴）和高度（Y轴）的参数，这个是css3中2D的转换方法。*/
  to {
    transform: translate3d(-50%, -50%, 0) scale(0.8);
  }
}
</style>
