<template>
  <div class="pages col-start-center">
    <van-sticky style="width: 100%;">
      <header class="nav-bar row-between-center">
        <div @click="newAppBack" class="nav-bar-left"><van-icon name="arrow-left" /></div>
        <!-- <div class="nav-bar-title row-center-center fw6">卡券商城 &nbsp;<van-icon name="arrow-up" /><div class="more-label row-center-center f14 fw6">更多优惠</div></div> -->

        <div class="nav-bar-title">
          <van-dropdown-menu>
            <van-dropdown-item ref="item" @open="dropOpen" @close="dropClose">
              <template #title>
                <div class="row-center-center">
                  {{ activityItem.activityName }}
                  &nbsp;<van-icon :class="['arrow fw6', isDropOpen ? 'active' : '']" name="arrow-down" />
                  <div class="more-label row-center-center f14 fw6">更多优惠</div>
                </div>
              </template>
              <div @click="chooseShop(item)" :class="['dropdown-item row-start-center f18', activityItem.id === item.id ? 'active fw6' : '']" v-for="item in shopList" :key="item.id">{{item.activityName}}</div>
            </van-dropdown-item>
          </van-dropdown-menu>

        </div>


        <div class="nav-bar-right"></div>
      </header>
      <div v-if="AI.partitions.subfieldsInfos" class="row-start-center second-nav">
        <div v-for="(item, index) in AI.partitions.subfieldsInfos" :key="index" @click="setTabIndex(item)" :class="['row-center-center flex-none', curSubfield.id === item.id ? 'active fw6' : '']">{{ item.menuName }}</div>
      </div>
    </van-sticky>

    <!-- 私教单独 筛选区 -->
    <div v-show="curSubfield.menuName.indexOf('包月私教') > -1 || curSubfield.menuName.indexOf('私教课包') > -1" @click="isPriGroupShow = true" class="pri-nav row-start-center">
      <div class="item row-between-center f14 fw6">{{ priGroupName }}<van-icon name="arrow-down" /></div>
    </div>
    <van-action-sheet v-model="isPriGroupShow" :actions="priGroupActions" @select="onPriGroupSelect" />

    <div class="list" v-if="groupInfos.length">
      <div v-show="!(curSubfield.menuName.indexOf('包月私教') > -1 || curSubfield.menuName.indexOf('私教课包') > -1) || ((curSubfield.menuName.indexOf('包月私教') > -1 || curSubfield.menuName.indexOf('私教课包') > -1) && priGroupName === item.groupName)" v-for="item in groupInfos" :key="item.id" class="group">
        <div class="bold groupName">{{ item.groupName }}</div>
        <goods-item v-for="v in item.goodsInfos" :item="v" :num="curShopCart.selectionCount[v.id]" :key="v.id" @cartChange="cartChange"></goods-item>
      </div>
    </div>
    <div class="list-empty" v-else>
      <van-empty image-size="26vw">
        <div class="f16" style="color: #6c727a">暂无活动商品</div>
      </van-empty>
    </div>

    <!-- 吸底 -->
    <div v-if="shopCartInfo.length > 1" class="sticky-bottom row-between-center f18 fw6">
      <div @click="chooseCart(item)" v-for="item,index in shopCartInfo" :key="item.shoppingCartId" :class="['case-item row-end-center', curShopCart.shoppingCartId === item.shoppingCartId ? 'active' : '']">
        <img class="case-tag" :src="`https://img.chaolu.com.cn/ACT/talk-order/202408_case${index+1}.png`" alt="">
        <div v-show="item.deductionPrice" class="p2 f14 row-center-center" style="left: 24%">立省{{ item.deductionPrice }}元</div>
        <p class="price f24 row-start-end"><span class="f16">￥</span>{{ item.salePrice }}</p>
      </div>

      <div @click="openComparison" class="case-item long row-center-center">
        <img class="icon" src="https://img.chaolu.com.cn/ACT/talk-order/202408_compare.png" alt="" />
        方案对比
      </div>
    </div>

    <!-- 购物车 -->
    <vue-ball ref="ball" :duration="600" :ball-nums="5">
      <div @click="openShopCart" @animationend="cartTranstioned" :class="['shopping-cart row-center-center', isCartShow ? 'active' : '', heartBeat ? 'is-heartBeat' : '']">
        <van-icon class="fw6" size="36px" name="https://img.chaolu.com.cn/PAD/icon/cart.png" />
        <p class="num f18 fw6 row-center-center">{{ curShopCart.badgeNum }}</p>
      </div>
      <van-icon slot="icon" class="fw6" size="34" name="https://img.chaolu.com.cn/MINI/coach-feelings/logo.png" />
    </vue-ball>

    <!-- 购物车弹窗 -->
    <van-popup class="cart-popup" v-model="isCartShow" position="bottom" round safe-area-inset-bottom>
      <div class="title row-between-center">
        <van-icon name="cross" style="opacity: 0" />
        <p class="f20 fw6">购物车</p>
        <van-icon @click="isCartShow = false" name="cross" />
      </div>
      <div class="nav row-between-center">
        <div @click="chooseCart(item)" v-for="item,index in shopCartInfo" :key="item.shoppingCartId" :class="['nav-item row-center-center fw6 flex-auto', curShopCart.shoppingCartId === item.shoppingCartId ? 'active' : '']">
          <p class="f18">方案{{ index + 1 }}</p>
          <p v-show="item.deductionPrice" class="f16 p2">立省{{ item.deductionPrice }}元</p>
        </div>
      </div>
      <div class="con">
        <div class="scroll-con">
          <div class="cart-title row-between-center">
          <p class="f16">已选 <span class="f28 fw6 BebasNeueBold">{{ curShopCart.badgeNum }}</span> 个</p>
          <div @click="addordeleteShopCart" :class="['btn row-center-center f14', shopCartInfo.length > 1 ? 'del' : 'add fw6']">{{ shopCartInfo.length > 1 ? '删除本方案' : '新增方案' }}</div>
        </div>

        <div v-show="curShopCart.shoppingCartId === cart.shoppingCartId" class="goods-list" v-for="cart,index in shopCartList" :key="index">
          <goods-item v-for="v in cart.goodsList" :item="v" :key="v.id" :num="v.count"  @cartChange="cartChange"></goods-item>
        </div>

        <div v-show="!curShopCart.badgeNum" class="empty col-center-center">
          <img src="https://img.chaolu.com.cn/ACT/talk-order/202408_empty.png" alt="">
          <p class="f18">暂未添加商品</p>
        </div>
        </div>
      </div>

      <div class="bottom f18 fw6 flex">
        <div class="price-box row-end-center flex-auto">
          <div v-show="curShopCart.deductionPrice" class="discount row-center-center f14">立省{{ curShopCart.deductionPrice }}元</div>
          <div class="row-start-center" style="vertical-align: baseline">
            <span>合计 </span>
            <span class="dis-price"><span>￥</span><span class="f28">{{ curShopCart.salePrice }}</span></span>
            <span v-show="curShopCart.originPrice" class="f14 ori-price"> ￥{{ curShopCart.originPrice }}</span>
          </div>
        </div>
        <div @click="getQrcode('', curShopCart.salePrice)" :class="['create-box row-center-center', curShopCart.salePrice ? '' : 'disabled']"><img src="https://img.chaolu.com.cn/ACT/talk-order/202408_qr.png" alt="">购买</div>
      </div>
    </van-popup>

    <!-- 方案对比弹窗 -->
    <van-popup class="cart-popup" v-model="isComparisonShow" position="bottom" round safe-area-inset-bottom>
      <div class="title row-between-center">
        <van-icon name="cross" style="opacity: 0" />
        <p class="f20 fw6">方案对比</p>
        <van-icon @click="isComparisonShow = false" name="cross" />
      </div>
      <div class="nav row-center-center f18 fw6">
        <div class="nav-item-2 row-center-center flex-auto">方案1</div>
        <img src="https://img.chaolu.com.cn/ACT/talk-order/202408_vs.png" alt="" />
        <div class="nav-item-2 row-center-center  flex-auto">方案2</div>
      </div>
      <div class="con small" v-if="shopCartComparison[0]">
        <div class="scroll-con">
          <div class="con-half flex" v-for="block,bIndex in shopCartComparison[0].shoppingCartDetailItems" :key="bIndex">
          <div class="goods-list g2" v-for="cart,cIndex in 2" :key="cIndex">
            <p class="name f22 fw6">{{ shopCartComparison[cIndex].shoppingCartDetailItems[bIndex].subfieldName }}</p>
            <template v-for="v in shopCartComparison[cIndex].shoppingCartDetailItems[bIndex].goodsList">
              <goods-item v-if="v.id" :item="v" :num="v.count" :isCanAdd="false" size="small" :key="v.id"></goods-item>
            </template>
            <div v-if="shopCartComparison[cIndex].shoppingCartDetailItems[bIndex].goodsList[0].id === null" class="empty col-center-center">
              <img src="https://img.chaolu.com.cn/ACT/talk-order/202408_empty.png" alt="">
              <p class="f14">此方案无{{ shopCartComparison[cIndex].shoppingCartDetailItems[bIndex].subfieldName }}</p>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div class="bottom f18 fw6 flex">
        <div class="price-box row-end-center flex-auto" v-for="item,index in shopCartInfo" :key="item.shoppingCartId">
          <div v-show="item.deductionPrice" class="discount row-center-center f12">立省{{ item.deductionPrice }}元</div>
          <div class="row-start-center" style="vertical-align: baseline">
            <span class="dis-price-2"><span>￥</span><span class="f28">{{ item.salePrice }}</span></span>
          </div>
          <div @click="getQrcode(index, item.salePrice)" :class="['create-box-2 row-center-center', item.salePrice ? '' : 'disabled']"><img src="https://img.chaolu.com.cn/ACT/talk-order/202408_qr.png" alt="">购买</div>
        </div>
        <!-- <div class="price-box row-end-center flex-auto">
          <div class="discount row-center-center f14">立省200元</div>
          <div class="row-start-center" style="vertical-align: baseline">
            <span class="dis-price-2"><span>￥</span><span class="f28">22000</span></span>
          </div>
          <div @click="getQrcode" class="create-box-2 row-center-center"><img src="https://img.chaolu.com.cn/ACT/talk-order/202408_qr.png" alt="">购买</div>
        </div> -->
      </div>
    </van-popup>

    <!-- 二维码弹窗 -->
    <van-popup v-model="isQrShow" :style="{ padding: '64px', background: 'transparent' }">
      <div class="popup-box row-center-center">
        <!--                <h4>扫码购买最新优惠卡券</h4>-->
        <img :src="'data:image/png;base64,' + qrCode" alt="" />
      </div>
      <img @click="isQrShow = false" class="result-close" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double11-2021/result-close.png" alt="" />
    </van-popup>
    <!-- 特殊活动 二维码弹窗 -->
    <van-popup v-model="isBigSaleShow" :style="{ padding: '64px', background: 'transparent' }">
      <div class="popup-big col-start-center">
        <h3 class="f22 fw6">{{ activityItemTypeQr.activityName }}</h3>
        <p class="p1 f14">活动时间：{{  activityItemTypeQr.timeStr }}</p>
        <div class="bg col-start-center">
          <img :src="`data:image/png;base64,${activityItemTypeQr.qrcode}`" alt="" />
          <p class="p2 f16 fw6">{{activityItemTypeQr.teacherName}}教练的邀请码</p>
        </div>
        <p class="p3 f14">使用微信/超鹿APP扫码即可查看</p>

      </div>
      <img @click="isBigSaleShow = false" class="result-close" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double11-2021/result-close.png" alt="" />
    </van-popup>
    <!-- 确认删除 -->
    <confirm
      type="1"
      :zIndex="10003"
      :showConfirm="isConfirmShow"
      title="确认删除？"
      text="删除后，该方案无法恢复"
      :btnTextArr="['我再想想', '确认删除']"
      @firstBtn="isConfirmShow = false"
      @secondBtn="confirmSure"
    />

  </div>
</template>

<script>
import { initBack, newAppBack, hideAppBackBtn } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import { getAuthorization } from '@/views/ipad/ipadMethod'
import userMixin from '@/mixin/userMixin'
import { Ball as vueBall } from '@hyhello/vue-ball'
import goodsItem from './components/goods-item'
import confirm from '@/components/confirm/confirm'

export default {
  mixins: [userMixin],
  name: 'mall',
  components: {
    vueBall,
    goodsItem,
    confirm
  },
  data() {
    return {
      isPriGroupShow: false, // 私教单独筛选actionsheet
      priGroupName: '',
      isQrShow: false,
      isCartShow: false, // 购物车弹窗
      isComparisonShow: false, // 方案对比弹窗
      isConfirmShow: false, // 确认弹窗

      subfieldIndex: 0,
      curSubfield: {}, // 当前分栏

      AI: { partitions: {} },
      groupInfos: [],
      qrCode: '',

      shopList: [], // 商城列表
      activityItem: {},
      activityItemTypeQr: {}, // 大促 只弹二维码 对象
      isBigSaleShow: false, // 大促 只弹二维码
      isDropOpen: false,
      shareConfig: {
        // 分享配置
        webUrl: '',
        posterUrl: '',
      },

      shopCartInfo: [],  // 购物车详情,
      shopCartList: [],
      curShopCart: {},

      shopCartComparison: [], // 方案对比

      heartBeat: false,

    }
  },
  computed: {
    priGroupActions() {
      if (!this.curSubfield.groupInfos) return

      let arr = []
      this.curSubfield.groupInfos.map((item) => {
        arr.push({name: item.groupName})
      })
      return arr
    },
  },
  watch: {
    curSubfield(v) {
      // 处理包月私教
      if (v.menuName.indexOf('包月私教') > -1 || v.menuName.indexOf('私教课包') > -1) {
        const initPrice = getParam().price || v.groupInfos[0].groupName
        // 分组名称包含这个价格 说明该教练是这个档位的
        const sameArr = v.groupInfos.filter((item) => item.groupName.indexOf(initPrice) > -1)
        this.priGroupName = sameArr[0].groupName
      }
    }
  },
  async created() {
    const u = navigator.userAgent
    console.log('agent', u);
    console.log('isios', this.appTypeStr);

    // 初始化
    hideAppBackBtn()
    initBack()
    this.userId_ = getParam().userId
    this.authorization = await getAuthorization()
    await this.$getAllInfo(['cityId', 'teacherId'])
    console.log('userId', this.userId);

    console.log('create', this.authorization)
    this.authorization = this.authorization || 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJqaWFuZ2pyIiwiYXBwTmFtZSI6IumAmueUqOezu-e7nyIsInNjb3BlIjpbImFsbCJdLCJhcHBJZCI6NCwib2xkVXNlcklkIjoiMTA4MjYyODQ0NjY5OTM4ODkyOCIsImV4cCI6MTcyOTEwMzg1MiwidXNlcklkIjo4OSwiYXV0aG9yaXRpZXMiOlsiW10iXSwianRpIjoiNWMzZmRkZGUtNDY4ZC00OGVkLTk0MWItNDQ2MjI4NmViZGEyIiwiY2xpZW50X2lkIjoiNCIsInVzZXJuYW1lIjoiamlhbmdqciJ9.RQ1aYZz8Z9m76O4nsEF7lFcY359MNmrMoWanhK4NiZ4'

    console.log('cid', this.cityId)

    await this.getNavList()
    this.getShop()
    this.getShopCart()
    this.getShareConfig()
  },

  methods: {
    newAppBack,

    // 商品增加
    cartChange(obj) {
      const toast1 = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.$axios.post(this.baseURLApp + '/ipad/user/teaching/template/addShoppingCart', {
        count: obj.num,
        goodsId: obj.item.id,
        itemId: obj.item.itemInfos ? obj.item.itemInfos[0].id : obj.item.itemId,
        originalPrice: obj.item.originPrice,
        salePrice: obj.item.salePrice,
        shoppingCartId: this.curShopCart.shoppingCartId,
        shopId: this.activityItem.id,
        userId: this.userId_
      }).then((r) => {
        this.getShopCart()
        // 购物车打开并且该数量为0时 重刷一遍购物车列表接口
        if (this.isCartShow && obj.num === 0) this.getShopCartList()
        toast1.clear()
      }).catch(() => {
        this.curShopCart.selectionCount[obj.item.id] = obj.num--
        toast1.clear()
      })
      if (obj.type === 'PLUS') {
        this.$refs.ball.action(obj.ev.target).then(() => {
          this.heartBeat = true
        })
      }

    },
    // 加入球之后
    cartTranstioned() {
      this.heartBeat = false
    },
    getNavList() {
      return new Promise((resolve, reject) => {
        this.$axios.post(this.baseURLApp + '/activityShopWeb/querySportsPlanningShop', {
          userId: this.userId_,
          teacherId: this.teacherId,
          fromIpad: true,
          authorization: this.authorization,
        })
        .then((r) => {
          this.shopList = r.data
          const first = r.data.filter((item) => !item.qrCodeModel)
          this.activityItem = first[0]
          resolve()
        })
      })
    },
    chooseShop(item) {
      // 只弹二维码的情况 购物车不刷新
      this.$refs.item.toggle()

      if (item.qrCodeModel) {
        this.isBigSaleShow = true
        this.activityItemTypeQr = item
        this.getBigSaleQrcode()
      } else {
        this.activityItem = item
        this.getShop()
        this.getShopCart()
        this.getShareConfig()
      }
    },
    dropOpen() {
      this.isDropOpen = true
    },
    dropClose() {
      this.isDropOpen = false
    },
    getShop() {
      let toast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        overlay: true,
        message: '加载中...',
        loadingType: 'spinner',
      })
      this.$axios.post(this.baseURLApp + '/activityShopWeb/getShop', {
        shopId: this.activityItem.id,
        userId: this.userId_,
        cityId: this.cityId,
      })
      .then((r) => {
        this.AI = r.data
        this.curSubfield = r.data?.partitions?.subfieldsInfos?.[0] || {}
        this.getGoodStatus(this.curSubfield.groupInfos)
        toast.clear()

      })
      .catch((r) => {
        this.AI = { partitions: {} }
        this.groupInfos = []
      })
    },
    // 获取购物车详情
    getShopCart(isAdd) {
      this.$axios.post(this.baseURLApp + '/ipad/user/teaching/template/getShoppingCarBadge', {
        userId: this.userId_,
        shopId: this.activityItem.id,
      }).then((r) => {
        this.shopCartInfo = r.data

        if (isAdd) {
          // 新增方案的情况
          this.curShopCart = r.data[1]
        } else {
          const cur = r.data.filter((item) => item.shoppingCartId === this.curShopCart.shoppingCartId)
          this.curShopCart = cur?.[0] || r.data[0]
        }

      })
    },
    // 选择购物车
    chooseCart(item) {
      this.curShopCart = item
      this.getShopCartList()
    },
    openShopCart() {
      this.isCartShow = true
      this.getShopCartList()
    },

    addordeleteShopCart() {
      const isMore = this.shopCartInfo.length > 1
      const rurl = isMore ? 'delShoppingCart' : 'createShoppingCart'

      // isMore: true 删除
      if (isMore && !this.isConfirmShow) {
        this.isConfirmShow = true
        return
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...',
        loadingType: 'spinner',
      })
      this.$axios.post(`${this.baseURLApp}/ipad/user/teaching/template/${rurl}`, {
        userId: this.userId_,
        shoppingCartId: isMore ? this.curShopCart.shoppingCartId : undefined
      })
      .then((r) => {
        this.getShopCart(!isMore)
        this.isCartShow = false
        this.isConfirmShow = false
        this.$toast.clear()
      }).catch(() => { this.$toast.clear() })
    },
    // 获取购物车方案商品列表
    getShopCartList(shoppingCartId) {
      return this.$axios.post(this.baseURLApp + '/ipad/user/teaching/template/getShoppingCart', {
        userId: this.userId_,
        shoppingCartId: shoppingCartId || this.curShopCart.shoppingCartId,
        shopId: this.activityItem.id,
      })
      .then((r) => {
        this.shopCartList = r.data

      })
    },
    openComparison() {
      this.isComparisonShow = true
      this.getShopCartComparison()
    },
    // 购物车方案对比
    getShopCartComparison() {
      this.$axios.post(this.baseURLApp + '/ipad/user/teaching/template/getShoppingCartComparison', {
        userId: this.userId_,
        shopId: this.activityItem.id,
      })
      .then((r) => {
        this.shopCartComparison = r.data
      })
    },
    getShareConfig() {
      this.shareConfig.webUrl = `${window.location.origin}/#/superdeer-activity/marketing/shoppingcart-settlement`
    },
    setTabIndex(v) {
      this.curSubfield = v
      this.getGoodStatus(v.groupInfos)
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      })
    },
    onPriGroupSelect(item) {
      this.priGroupName = item.name
      this.isPriGroupShow = false
    },
    // 获取当前菜单tab下商品的id集合 单独获取商品的购买状态 结果用id匹配 {34: 'CAN_BUY'}
    getGoodStatus(groupInfos) {
      const toast1 = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      let idArr = []
      groupInfos.map((group) => {
        group.goodsInfos.map((goods) => {
          idArr.push(goods.id)
        })
      })
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsStatus`, {
        goodsId: idArr,
        shopId: this.activityItem.id,
        userId: this.userId_,
      })
      .then((res) => {
        toast1.clear()
        this.goodsStatusMap = res.data.goodsStatusMap
        let list = []
        console.time('aa')
        groupInfos.forEach((v) => {
          let itemList = []
          v.goodsInfos.forEach((r, index) => {
            if (this.goodsStatusMap[r.id] && this.goodsStatusMap[r.id] === 'CAN_BUY') {
              itemList.push(r)
            }
          })
          v.goodsInfos = itemList
          if (itemList.length) {
            list.push(v)
          }
        })
        this.groupInfos = list
        console.timeEnd('aa')
        this.$forceUpdate()
      })
    },
    confirmSure() {
      this.addordeleteShopCart()
    },
    async getQrcode(index, price) {

      if (!price) return

      let toast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...',
        loadingType: 'spinner',
      })

      const shoppingCartId = index === '' ? this.curShopCart.shoppingCartId : this.shopCartInfo[index].shoppingCartId

      const checkResult = await this.checkMultiGoodsItem(shoppingCartId)
      if (!checkResult.passCheck) {
        this.$notify({
          message: checkResult.errorMsg,
          duration: 8000,
        })
        toast.clear()
        return
      }

      this.$axios.post(this.baseURLApp + '/qrCode/createOrdinaryCode/qrCode', {
        authorization: this.authorization,
        width: 240,
        height: 240,
        webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
        scene: JSON.stringify({
          shoppingCartUserId: this.userId_,
          source: this.activityItem.id,
          id: this.activityItem.id,
          shoppingCartId,
          from: 'teaching_template',
          webUrl: this.shareConfig.webUrl,
        }),
      })
      .then((res) => {
        this.qrCode = res.data.qrCode
        this.isQrShow = true
      })
      .finally((r) => {
        toast.clear()
      })
    },
    getBigSaleQrcode() {
      this.$axios.post(this.baseURLApp + '/qrCode/createOrdinaryCode/qrCode', {
        width: 240,
        height: 240,
        webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
        scene: JSON.stringify({
          source: this.activityItemTypeQr.id,
          id: this.activityItemTypeQr.id,
          teacherId: this.teacherId || '850018792234930176',
          from: 'teaching_template',
          webUrl: `${this.activityItemTypeQr.link}`,
        }),
      })
      .then((res) => {
        this.$set(this.activityItemTypeQr, 'qrcode', res.data.qrCode)
        this.isBigSaleShow = true
      })
    },
    async checkMultiGoodsItem(shoppingCartId) {
      if (this.isComparisonShow) await this.getShopCartList(shoppingCartId)
      const arr1 = this.shopCartList.filter((item) => item.shoppingCartId === shoppingCartId)
      const shoppingCartList = arr1[0]

      const arr2 = this.shopCartInfo.filter((item) => item.shoppingCartId === shoppingCartId)
      const shoppingCart = arr2[0]

      let items = []
      shoppingCartList.goodsList.map((item) => {
        items.push({
          id: item.itemInfos[0].id,
          num: shoppingCart.selectionCount[item.id],
        })
      })

      return this.$axios.post(`${this.baseURLApp}/activityShopWeb/checkMultiGoodsItemStatus`, {
        items,
        shopId: this.activityItem.id,
        userId: this.userId_,
      })
      .then((res) => {
        return res.data
      })
    }
  },
}
</script>

<style scoped lang="less">
.pages {
  background: #f9fafb;
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }
}
.list-empty {
  flex-grow: 1;
  padding-top: 100px;
}
.sticky-bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 12px calc(12px + env(safe-area-inset-bottom));
  background-color: #fff;
  .case-item {
    width: 264px;
    padding: 0 12px;
    height: 55px;
    border-radius: 6px;
    border: 2px solid #dddddd;
    position: relative;
    &.active {
      border: 2px solid #3c454e;
    }
    &.long {
      width: 176px;
      background-color: #242831;
      color: #fff;
      border-color: #242831;
    }
    .p2 {
      padding: 0 7px;
      height: 23px;
      background: linear-gradient(90deg, #FC7411 0%, #FC2C11 100%);
      border-radius: 4px;
      color: #fff;
      margin: 0 8px 0 0;
    }
    .case-tag {
      position: absolute;
      left: 0;
      top: 0;
      width: 46px;
    }
    .icon {
      width: 18px;
      margin: 0 9px 0 0;
    }
  }
}
.cart-popup {
  .title {
    width: 100%;
    height: 63px;
    padding: 0 24px;
    position: relative;
    .icon-close {
      position: absolute;
      right: 24px;
      top: 24px;
    }
  }
  .nav {
    padding: 5px 20px 16px;
    border-bottom: 1px solid #eee;
    .nav-item {
      width: 349px;
      height: 43px;
      background: #f5f5f5;
      border-radius: 6px;
      border: 2px solid #f5f5f5;
      &.active {
        background: #fff;
        border: 2px solid #3c454e;
      }
      &:not(:first-child) {
        margin: 0 0 0 6px;
      }
      .p2 {
        color: #fc2c11;
        margin: 0 0 0 27px;
      }
    }
    img {
      width: 29px;
      margin: 0 3px;
    }
    .nav-item-2 {
      height: 47px;
      background: rgba(38, 125, 255, .1);
      border-radius: 6px;
    }
  }
  .con {
    width: 100%;
    padding: 20px 6px;
    height: 72vh;
    position: relative;
    &.small {
      padding: 0 6px 0;
      &:after {
        content: '';
        width: 2px;
        height: 100%;
        left: 50%;
        transform: translateX(-1px);
        position: absolute;
        top: 0;
        background-color: #F5F6FA;
      }
    }
    .scroll-con {
      height: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
    .empty {
      height: 70%;
      img {
        width: 143px;
        margin: 0 0 24px;
      }
    }
    .cart-title {
      margin: 0 0 27px;
      padding: 0 14px;
    }
    .con-half {
      width: 100%;
      height: fit-content;
      padding: 0 3px;

      .name {
        padding: 0 16px 6px;
      }
      .goods-list {
        width: 50%;
        padding: 27px 0 0 0;
        position: relative;
        &.g2 {
          padding: 27px 0 14px;
        }
        &:after {
          content: '';
          width: 90%;
          height: 1px;
          background-color: #eee;
          position: absolute;
          bottom: 0;
          left: 14px;
        }
      }
      .empty {
        height: 88%;
        img {
          width: 68px;
          margin: 0;
        }
        p {
          margin: 12px 0 0;
          color: #6C727A;
        }
      }
    }

    .btn {
      width: 112px;
      height: 39px;
      background: #ffffff;
      border-radius: 6px;

      &.del {
        border: 1px solid #eeeeee;
      }
      &.add {
        background-color: #267dff;
        color: #fff;
      }
    }
  }
  .bottom {
    height: 78px;
    .price-box {
      width: 100%;
      background-color: #242831;
      color: #fff;
      padding: 0 17px;
      position: relative;
      .discount {
        color: #fff;
        padding: 0 6px;
        height: 23px;
        background: linear-gradient(90deg, #fc7411 0%, #fc2c11 100%);
        border-radius: 4px;
        margin: 0 8px 0 0;
      }
      .dis-price-2 {
        margin: 0 12px 0 0;
      }
      .dis-price {
        margin: -6px 5px 0 5px;
      }
      .ori-price {
        text-decoration: line-through;
        color: #9aa1a9;
      }
      &:first-child {
        border-right: 0.5px solid #3A3F4A;
      }
      &:last-child {
        border-left: 0.5px solid #3A3F4A;
      }
    }
    .create-box {
      width: 195px;
      background-color: #ffde00;
      &.disabled {
        filter: grayscale(1)
      }
      img {
        width: 16px;
        margin: 0 11px 0 0;
      }
    }
    .create-box-2 {
      padding: 0 16px;
      height: 49px;
      background: #ffde00;
      border-radius: 6px;
      color: #000;
      &.disabled {
        filter: grayscale(1)
      }
      img {
        width: 18px;
        margin: 0 8px 0 0;
      }
    }

  }
}
.nav-bar {
  width: 750px;
  height: 68px;
  background: white;

  padding: calc(15px + env(safe-area-inset-top)) 20px 15px;
  box-sizing: border-box;
  .nav-bar-left {
    width: 120px;
    font-size: 24px;
  }
  .nav-bar-title {
    color: #000000;
    margin: 0 0 0 100px;
    .arrow {
      transition: all .3s;
      &.active {
        transform: rotateZ(180deg);
        transform-origin: center;
      }
    }
    .dropdown-item {
      width: 100%;
      height: 78px;

      padding: 0 20px;
      &.active {
        color: #267DFF;
        background: rgba(38, 125, 255, .05);
      }
    }
    .more-label {
      padding: 0px 8px;
      height: 23px;
      color: #fff;
      margin: 0 0 0 14px;
      background: linear-gradient(90deg, #FC7411 0%, #FC2C11 100%);
      border-radius: 4px;
    }
  }
  .nav-bar-right {
    width: 120px;
  }
}

.second-nav {
  padding: 20px;
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid #eee;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  div {
    height: 43px;
    border-radius: 4px;
    padding: 0 16px;

    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    & + div {
      margin-left: 20px;
    }
    &.active {
      background: #ffde00;
      border-color: #ffde00;
    }
  }
}
.pri-nav {
  width: 100%;
  margin: 18px 0 0;
  padding: 0 20px;
  .item {
    min-width: 195px;
    padding: 0 14px 0 16px;
    height: 39px;
    background: #ffffff;
    border-radius: 8px;
  }
}
.list {
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  margin: 18px 0 0;
  padding: 0 20px 100px;
  .group {
    background-color: white;
    border-radius: 10px;
    .groupName {
      font-size: 18px;
      border-bottom: 1px solid #eeeeee;
      padding: 16px 20px;
      font-weight: bold;
    }
    & + .group {
      margin: 0 0 20px;
    }
  }
}
.popup-box {
  width: 391px;
  height: 391px;
  background: #ffffff;
  border-radius: 10px;

  img {
    width: 350px;
    display: block;
    margin: 0 auto;
  }
}
.popup-big {
  width: 366px;
  padding: 20px;
  background: #ffffff;
  border-radius: 15px;
  color: #000;
  .p1 {
    margin: 4px 0 0;
  }
  .p3 {
    margin: 16px 0 0;
  }
  .bg {
    width: 278px;
    padding: 10px;
    background: #FFDE00;
    border-radius: 20px;
    margin: 12px 0 0;
    img {
      width: 100%;
      border-radius: 15px;
      border: 2px solid #000000;
    }
    .p2 {
      margin: 10px 0 0;
    }
  }

}
.result-close {
  width: 31px;
  height: 31px;
  margin: 31px auto 0;
  display: block;
}
.shopping-cart {
  width: 73px;
  height: 73px;
  background-color: #ffde00;
  border-radius: 50%;
  position: fixed;
  left: 20px;
  z-index: 99;
  bottom: calc(114px + env(safe-area-inset-bottom));
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  color: #000;
  transition: all 0.6s;
  &.active {
    bottom: calc(22px + env(safe-area-inset-bottom));
    box-shadow: none;
    z-index: 9999;
  }
  .num {
    min-width: 24px;
    padding: 0 8px;
    height: 24px;
    background: #ed5c42;
    border-radius: 50%;
    color: #fff;
    position: absolute;
    right: -4px;
    top: 0;
  }
}
.tag {
  width: 89px;
  height: 25px;
  background: #fc2e11;
  border-radius: 13px;
  position: absolute;
  top: -14px;
  color: #fff;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #fc2e11 transparent transparent transparent;
    position: absolute;
    top: 98%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.is-heartBeat {
  animation-name: heartBeat;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}
@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.red {
  color: #fc2c11;
}
/deep/.van-popup {
  overflow: initial;
}
/deep/.van-dropdown-menu__bar {
  box-shadow: none;
  .van-dropdown-menu__title {
    font-size: 18px;
    font-weight: 600;
    color: #000;
  }
  .van-dropdown-menu__title--down::after {
    display: none;
  }
  .van-dropdown-menu__title::after {
    display: none;
  }
}
</style>
<style>
.vue-ball {
  z-index: 99999 !important;
}
</style>
